import React from 'react'

const ApplicationOver = ({ headingDE, headingEN }) => (
  <div
    className="w-100 d-flex pt-5 flex-column justify-content-center align-items-center text-center"
    style={{ marginBottom: '7rem' }}
  >
    <h1 className="text-primary">{headingDE}</h1>
    <p>Die Bewerbungsphase ist für 2024 abgeschlossen.</p>
    <br />
    <h1 className="text-primary mt-5">{headingEN}</h1>
    <p>Application period is closed for 2024.</p>
  </div>
)

export default ApplicationOver
