import React from 'react'

const ApplicationAnnouncement = ({ headingDE, headingEN }) => (
  <div
    className="w-100 d-flex pt-5 flex-column justify-content-center align-items-center text-center"
    style={{ marginBottom: '7rem' }}
  >
    <p className="my-3 px-5">
      Der Zugang steht ab ca. August 2024 wieder zur Verfügung.
      </p>
      <p className="my-3 px-5">
        Access will be available again from August 2024.
      </p>
    {/* <h1 className="text-primary">{headingDE}</h1>
    <p>
      Bewerbungsdaten hochladen:
      <br />
      Start: 7. August 18:00 Uhr
    </p>

    <p>
      <b>
        KUBOSHOW KUNSTMESSE 23
        <br />
        21.- 22. Oktober 23
      </b>
    </p>
    <br />
    <br />
    <h1 className="text-primary mt-5">{headingEN}</h1>
    <p>
      Upload application data:
      <br />
      Start: 7 August 6pm
    </p>

    <p>
      <b>
        KUBOSHOW KUNSTMESSE 23
        <br />
        21- 22 Oktober 23
      </b>
    </p> */}
  </div>
)

export default ApplicationAnnouncement
