import React from 'react'

const ApplicationMaintenance = () => (
  <div className="text-center mt-4">
    <p>
      Das Bewerbungsformular wird gerade gewartet und ist morgen wieder
      verfügbar. Die Bewerbungsfrist verlängert sich dadurch bis zum 1.
      September.
    </p>
    <br />
    <p>
      The application form is under maintenance and will be available again
      tomorrow. The application deadline is postponed to 1st of September.
    </p>
  </div>
)

export default ApplicationMaintenance
